import Vue from 'vue';

/**
 * @typedef {Object} CompanyRequirementCategoryHT
 * @property {number} id
 * @property {string} name
 * @property {bool} display_survey
 * @property {bool} display_chatbot
 * @property {bool} is_duplicable
 * @property {bool} is_locked
 * @property {bool} is_notifiabl
 * @property {number} order
 * @property {number} number_of_active_user_survey
 * @property {Array} hard_deleted_requirements_ids
 * @property {Resource} resource
 * @property {CompanyRequirementHT[]} company_requirement
 * @property {CompanyRequirementCategorySupervisor[]} company_requirement_category_supervisor
*/

/**
 * @typedef {Object} CompanyRequirementHT
 * @property {number} id
 * @property {string} question_multiple_value
 * @property {string} type
 * @property {bool} is_heyteam
 * @property {bool} is_mandatory
 * @property {Array} media
 * @property {int|null} file_max_size
 * @property {int} order
 * @property {bool} can_fill_document
 * @property {Object<string, any>} translations
 * @property {Object<string, FileTranslation|null>} fileTranslations
 * @property {Object<string, FileTranslation|null>} file_translations
 * @property {bool} sign_after_completed
 * @property {number} company_pdf_form_template_id
*/

/**
 * @typedef {Object} CompanyRequirementCategorySupervisor
 * @property {number} id
 * @property {number} company_role_id
 * @property {Role|null} role
 */

export default {
    getAll(data = {}) {
        return Vue.prototype.$http.post('configuration/requirement-categories/list', data);
    },

    get(id, data = {}) {
        return Vue.prototype.$http.get(`configuration/requirement-categories/${id}`, data);
    },

    create(data = {}) {
        return Vue.prototype.$http.post('configuration/requirement-categories', data);
    },

    update(id, data = {}) {
        return Vue.prototype.$http.post(`configuration/requirement-categories/${id}`, data);
    },

    delete(id, data = {}) {
        return Vue.prototype.$http.delete(`configuration/requirement-categories/${id}`, data);
    },
};
