<template>
    <div
        ref="table-container"
        class="table-container"
    >
        <table
            v-if="loading || rows.length > 0 || Object.keys(filters).length > 0"
            :class="{'hide-phone': !!$scopedSlots.responsive}"
            :data-cy="cypress"
        >
            <thead v-if="showHeader">
                <tr>
                    <th
                        v-if="hasCheckboxes"
                        class="table-cell-checkbox"
                        @click.stop="$refs['table-cell-checkbox-header'].change()"
                    >
                        <HtTableCellCheckbox
                            ref="table-cell-checkbox-header"
                            v-model="allChecked"
                            :indeterminate="checkAllIndeterminate"
                            :is-header="true"
                        />
                    </th>
                    <th
                        v-for="(column, columnIndex) in columns"
                        :key="columnIndex"
                        ref="thEl"
                        :class="thClass(column)"
                        :style="thStyle(column)"
                    >
                        <div
                            class="th-inner"
                            @click="sortColumn(column)"
                        >
                            <div class="th-text">
                                <HtDeployableSearchField
                                    v-if="isFilterable(column)"
                                    v-model.trim="filters[column.key]"
                                    :placeholder="column.placeholder"
                                >
                                    {{ column.label }}
                                </HtDeployableSearchField>
                                <template v-else>
                                    <slot
                                        :name="headScope(column.key)"
                                        :value="column.label"
                                        :column="column"
                                    >
                                        {{ column.label }}
                                    </slot>

                                    <template v-if="isSortable(column)">
                                        <FontAwesomeIcon
                                            v-if="isColumnSorted(column)"
                                            :icon="['far', orderClass]"
                                        />
                                        <FontAwesomeIcon
                                            v-else
                                            class="ht-table-icon-sort"
                                            :icon="['far', 'sort-alt']"
                                        />
                                    </template>
                                </template>
                            </div>
                        </div>
                        <div
                            class="col-resizer branding-color"
                            @mousedown="startColResizing($event, columnIndex, column.key)"
                        />
                    </th>

                    <th
                        v-if="hasQuickActions"
                        class="quick-actions"
                    />
                </tr>
            </thead>

            <tbody>
                <template v-if="loading">
                    <tr
                        v-for="rowIndex in skeletonRows"
                        :key="`skeleton-${rowIndex}`"
                    >
                        <template v-if="hasCheckboxes">
                            <td
                                v-if="$scopedSlots['skeleton(checkbox)']"
                                :key="`slot-skeleton-${rowIndex}`"
                            >
                                <slot name="skeleton(checkbox)" />
                            </td>
                            <td
                                v-else-if="$scopedSlots[cellScope('checkbox')]"
                                :key="`big-skeleton-${rowIndex}`"
                            >
                                <Skeleton
                                    width="100%"
                                    height="35px"
                                    :loading="true"
                                />
                            </td>
                            <td
                                v-else
                                :key="`skeleton-${rowIndex}`"
                            >
                                <Skeleton
                                    width="100%"
                                    :loading="true"
                                />
                            </td>
                        </template>

                        <template
                            v-for="(column, columnIndex) in columns"
                        >
                            <td
                                v-if="$scopedSlots[`skeleton(${column.key})`]"
                                :key="`slot-skeleton-${rowIndex}-${columnIndex}`"
                            >
                                <slot :name="`skeleton(${column.key})`" />
                            </td>
                            <td
                                v-else-if="$scopedSlots[cellScope(column.key)]"
                                :key="`big-skeleton-${rowIndex}-${columnIndex}`"
                            >
                                <Skeleton
                                    width="100%"
                                    height="35px"
                                    :loading="true"
                                />
                            </td>
                            <td
                                v-else
                                :key="`skeleton-${rowIndex}-${columnIndex}`"
                            >
                                <Skeleton
                                    width="100%"
                                    :loading="true"
                                />
                            </td>
                        </template>
                    </tr>
                </template>
                <template v-else>
                    <tr
                        v-for="(row, rowIndex) in rows"
                        :key="rowIndex"
                        :class="{clickable: clickable}"
                        @click="$emit('on-row-clicked', row)"
                    >
                        <td
                            v-if="hasCheckboxes"
                            class="table-cell-checkbox"
                            @click.stop="$refs[`table-cell-checkbox-${rowIndex}`][0].change()"
                        >
                            <slot
                                :name="cellScope('checkbox_cell')"
                                :value="[]"
                                :item="row.item"
                            >
                                <HtTableCellCheckbox
                                    v-if="!permission || (permission && $canCreate(permission, {id: -1}))"
                                    :ref="`table-cell-checkbox-${rowIndex}`"
                                    :value="row.item.is_checked"
                                    @input="onToggleCheckbox(row.item)"
                                />
                            </slot>
                        </td>

                        <td
                            v-for="(cell, cellIndex) in row.cells"
                            :key="cellIndex"
                            :class="tdClass(cell)"
                            :style="tdStyle(cellIndex)"
                        >
                            <slot
                                :name="cellScope(cell.key)"
                                :value="cell.value"
                                :item="cell.item"
                            >
                                {{ cell.value }}
                            </slot>
                        </td>

                        <td
                            v-if="hasQuickActions"
                            class="quick-actions"
                        >
                            <slot
                                :name="cellScope('quick_actions')"
                                :value="row.item.quick_actions || []"
                                :item="row.item"
                            >
                                <TableCellQuickActions
                                    v-if="(!permission || (permission && $canUpdate(permission))) && row.item.quick_actions.length > 0"
                                    :actions="row.item.quick_actions"
                                    @action-clicked="onActionClicked(row.item, ...arguments)"
                                />
                            </slot>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
        <div
            v-else
            class="h-100 d-flex flex-column justify-content-center align-items-center py-5"
        >
            <div class="mb-5">
                <img
                    src="/static/icons/survey/tabs/survey_no_result.svg"
                    class="d-block mx-auto"
                >
            </div>

            <div class="text-center">
                <t>{{ emptyMessage }}</t>
            </div>
        </div>
    </div>
</template>

<script>
import get from 'lodash.get';
import Vue from 'vue';
import CypressMixin from '@/mixins/CypressMixin';
import HtDeployableSearchField from '@/components/globals/HtDeployableSearchField.vue';
import TableCellQuickActions from '@/components/globals/table/TableCellQuickActions.vue';
import { Skeleton } from 'vue-loading-skeleton';
import { HtTableCellCheckbox } from '@/components/globals/table';

export default {
    name: 'HtTableExtended',
    components: {
        TableCellQuickActions,
        HtDeployableSearchField,
        Skeleton,
        HtTableCellCheckbox,
    },
    mixins: [
        CypressMixin,
    ],
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        columns: {
            type: Array,
            default: () => [],
        },
        showHeader: {
            type: Boolean,
            default: () => true,
        },
        emptyMessage: {
            type: String,
            default: () => 'There is nothing to display here for now...',
        },
        clickable: {
            type: Boolean,
            default: true,
        },
        externalSearch: {
            type: String,
            default: null,
        },
        externalSortKey: {
            type: String,
            default: null,
        },
        externalSortDirection: {
            type: String,
            default: null,
        },
        hasQuickActions: {
            type: Boolean,
            default: true,
        },
        hasCheckboxes: {
            type: Boolean,
            default: false,
        },
        permission: {
            type: String,
            default: null,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        skeletonRows: {
            type: Number,
            default: 15,
        },
        frontSort: {
            type: Boolean,
            default: false,
        },
        frontFilter: {
            type: Boolean,
            default: false,
        },
        cellErrors: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            allChecked: false,
            isContainerIsScrollable: false,
            search: null,
            sortKey: null,
            sortDirection: null,
            filters: {},
            resizedColumnEl: null,
            resizedColumnKey: null,
            resizedColumnStartOffset: 0,
        };
    },
    computed: {
        searchColumns() {
            return this.columns.filter((column) => column.search === true);
        },
        showSearch() {
            return this.searchColumns.length > 0;
        },
        rows() {
            let rows = this.items;
            if (this.frontSort) {
                rows = rows.sort((a, b) => {
                    const aString = get(a, this.sortKey) !== null && get(a, this.sortKey) !== undefined ? get(a, this.sortKey) : '';
                    const bString = get(b, this.sortKey) !== null && get(b, this.sortKey) !== undefined ? get(b, this.sortKey) : '';
                    if (this.sortKey === null) {
                        return 0;
                    }
                    if (typeof aString === 'number' && typeof bString === 'number') {
                        return this.sortDirection === 'asc'
                            ? aString - bString
                            : bString - aString;
                    }
                    return this.sortDirection === 'asc'
                        ? aString.toString().localeCompare(bString)
                        : bString.toString().localeCompare(aString);
                });
            }
            if (this.frontFilter) {
                rows = rows.filter((item) => {
                    if (typeof this.externalSearch === 'string' && this.searchColumns.length > 0) {
                        const search = this.externalSearch.toString()
                            .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                            .toUpperCase();

                        for (const column of this.searchColumns) {
                            const text = ['string', 'number'].includes(typeof get(item, column.key))
                                ? get(item, column.key).toString()
                                    .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                                    .toUpperCase()
                                : '';

                            if (text.indexOf(search) >= 0) {
                                return true;
                            }
                        }

                        return false;
                    }

                    return true;
                });
            }

            return rows
                .map((item) => {
                    const cells = this.columns.map((c) => ({
                        key: c.key,
                        item,
                        value: get(item, c.key),
                        column: c,
                    }));

                    return { cells, item };
                });
        },
        orderClass() {
            return this.sortDirection === 'asc' ? 'long-arrow-up' : 'long-arrow-down';
        },
        checkAllIndeterminate() {
            const someChecked = this.rows.some(({ item }) => item.is_checked);
            const allChecked = this.rows.every(({ item }) => item.is_checked);
            return someChecked && !allChecked;
        },
    },
    watch: {
        columns: {
            immediate: true,
            handler() {
                this.columns
                    .filter((column) => this.isFilterable(column))
                    .forEach((column) => {
                        Vue.set(this.filters, column.key, this.filters[column.key] || '');
                    });
                this.initResizableCols();
            },
        },
        filters: {
            deep: true,
            handler() {
                this.columns
                    .filter((column) => column.filterFunction)
                    .forEach((column) => column.filterFunction(this.filters[column.key]));
            },
        },
        allChecked(isAllChecked) {
            this.$emit('on-toggle-all-checkbox', isAllChecked);
        },
        rows() {
            this.initResizableCols();
        },
    },
    mounted() {
        if (this.$route.query.id) {
            const row = this.rows.find((row) => row.item.id === parseInt(this.$route.query.id, 10));
            if (row) {
                this.$emit('on-row-clicked', row);
            }
        }

        this.onResize();

        window.addEventListener('resize', this.onResize);
        document.addEventListener('mousemove', this.colResizing);
        document.addEventListener('mouseup', this.stopColResizing);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
        document.removeEventListener('mousemove', this.colResizing);
        document.removeEventListener('mouseup', this.stopColResizing);
    },
    methods: {
        onResize() {
            this.isContainerIsScrollable = this.$refs['table-container'].scrollWidth > this.$refs['table-container'].clientWidth;
            this.initResizableCols();
        },
        onActionClicked(item, actionId) {
            this.$emit('on-action-clicked', { item, actionId });
        },
        onToggleCheckbox(item) {
            this.$emit('on-toggle-checkbox', item);
        },
        tdClass(cell) {
            const classes = [];

            if (cell.column.class) {
                classes.push(...cell.column.class.split(' '));
            }

            if (this.cellErrors[cell.item.id]?.includes(cell.column.key)) {
                classes.push('error');
            }

            if (this.isColumnSorted(cell.column)) {
                classes.push('sorted');
            }

            return classes;
        },
        tdStyle(cellIndex) {
            return this.columns[cellIndex].style;
        },
        thClass(column) {
            const classes = [];

            if (column.headerClass) {
                classes.push(...column.headerClass.split(' '));
            }

            if (this.isSortable(column) || this.isFilterable(column)) {
                classes.push('clickable');

                if (this.isColumnSorted(column)) {
                    classes.push('sorted');
                }
            }

            if (this.resizedColumnKey === column.key) {
                classes.push('resizing');
            }

            return classes;
        },
        thStyle(column) {
            return column.style;
        },
        cellScope(key) {
            return `cell(${key})`;
        },
        headScope(key) {
            return `head(${key})`;
        },
        sortColumn(column) {
            if (this.isSortable(column) === false) {
                return;
            }

            const columnKey = column.sortKey || column.key;
            const sortKey = this.externalSortKey ?? this.sortKey;
            const sortDirection = this.externalSortDirection ?? this.sortDirection;

            if (columnKey !== sortKey) {
                this.sortKey = columnKey;
                this.sortDirection = 'asc';
            } else {
                this.sortKey = sortKey;
                this.sortDirection = sortDirection === 'desc' ? 'asc' : 'desc';
            }

            this.$emit('sort-column', {
                column: this.sortKey,
                direction: this.sortDirection,
            });
        },
        isSortable(column) {
            return column.sortable !== false;
        },
        isColumnSorted(column) {
            return this.isSortable(column) && this.sortKey === column.key;
        },
        isFilterable(column) {
            return column.filterFunction;
        },
        initResizableCols() {
            // Initialized only if there are rows and columns
            if (this.rows.length > 0 && this.columns.length > 0) {
                this.$nextTick(() => {
                    const cols = this.$el.querySelectorAll('thead > tr > th');
                    const autoWidth = this.$el.offsetWidth / cols.length;
                    const minCellWidth = 190;
                    for (let index = 0; index < cols.length; index++) {
                        const col = cols[index];
                        if (minCellWidth > autoWidth) col.style.width = `${minCellWidth}px`;
                    }
                });
            }
        },
        startColResizing(e, columnIndex, columnKey) {
            this.resizedColumnEl = this.$refs.thEl[columnIndex];
            this.resizedColumnKey = columnKey;
            this.resizedColumnStartOffset = this.resizedColumnEl.offsetWidth - e.pageX;
            // Overwrite the min/max-width set on columns
            this.resizedColumnEl.style.maxWidth = 'none';
            this.resizedColumnEl.style.minWidth = '0px';
        },
        stopColResizing() {
            this.resizedColumnEl = null;
            this.resizedColumnKey = null;
            this.resizedColumnStartOffset = 0;
        },
        colResizing(e) {
            if (this.resizedColumnEl) {
                this.resizedColumnEl.style.width = `${this.resizedColumnStartOffset + e.pageX}px`;
            }
        },

    },
};
</script>

<style lang="scss" scoped>
    @import "~@/styles/var";
    @import "~@/styles/design-system-colors.scss";

    .table-cell-checkbox {
        width: 65px !important;
        cursor: pointer;
    }

    .table-container {
        overflow-y: auto;
        width: 100%;

        table {
            width: 100%;
            border-collapse: separate;
            white-space: nowrap;
            table-layout:fixed;

            tr {
                th, td {
                    border-bottom: 1px solid $neutral-300;
                    border-right: 1px solid $neutral-300;
                    border-collapse: collapse;

                    &:nth-last-child(2) {
                        border-right: 0;
                    }
                    &:last-child {
                        border-right: 0;
                        border-left: 1px solid $neutral-300;
                    }
                }

                &:first-child {
                    th {
                        border-top: 1px solid $neutral-300;
                    }
                }

                td {
                    padding: 16px 24px;
                    font-size: 14px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &.error {
                        border: 1px solid $semantic-error;
                    }
                    &:first-child{
                        > div {
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            white-space: normal;
                            text-overflow: clip;
                            width: 100%;

                        }
                    }
                }
                .sorted {
                    background-color: $neutral-100;
                }

            }

            thead {
                th {
                    position: relative;
                    color: $neutral-600;
                    font-size: 12px;
                    background-color: #fff;
                    .th-inner {
                        padding: 16px 24px;
                        overflow: hidden;
                        user-select: none;
                    }
                    .th-text {
                        display: inline-block;
                    }

                    &:not(.sorted) {
                        font-weight: 600;
                    }

                    &.sorted {
                        font-weight: 700;
                        color: $neutral-black;
                    }
                    &:has(+ .quick-actions){
                        .col-resizer{
                            display: none;
                        }
                    }
                    &:last-child {
                        .col-resizer{
                            display: none;
                        }
                    }
                    &.resizing .col-resizer {
                        opacity: 1;
                        background: currentColor
                    }
                }
                .col-resizer {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: -3px;
                    width: 5px;
                    cursor: col-resize;
                    background: #e6e6e6;
                    opacity: 0.3;
                    z-index: 1;
                    &:hover {
                        background: currentColor
                    }
                }
            }

            tbody {
                tr {
                    &:hover {
                        background-color: $neutral-200;

                        td {
                            background-color: $neutral-200 !important;
                        }
                    }
                }
            }
        }
    }

@media (min-width: $desktop-small) {
    .table-container table tr td:first-child, .table-container table tr th:first-child{
        border-left: 0;
        position: sticky;
        left: 0;
        width: min-content;
        background: $neutral-white;
        z-index: 2;
        &.sorted {
            background-color: $neutral-100;
        }
    }

    .table-container table tr .quick-actions {
        position: sticky;
        right: 0px;
        width: min-content;
        background: $neutral-white;
        border-left: 1px solid $neutral-300;

        &.fixed {
            border-left: 1px solid $neutral-300;
        }
    }

}
</style>
