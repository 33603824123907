<template>
    <div>
        <div class="ht-table-wrapper">
            <div class="ht-table-header">
                <ResourceBarFilters @on-select-filter="fetchData()">
                    <template #title>
                        <div>
                            <h1 class="title">
                                {{ title }}
                            </h1>
                        </div>
                    </template>

                    <template #button>
                        <HtButton
                            v-if="$canCreate(permission, {id: -1})"
                            cypress="button-action-add"
                            @click.native="onAdd"
                        >
                            <t class="hide-mobile">
                                Add
                            </t>

                            <span class="hide-desktop">
                                <FontAwesomeIcon
                                    :icon="['fal', 'plus']"
                                    class="plus"
                                />
                            </span>
                        </HtButton>

                        <HtButton
                            v-if="canImport"
                            cypress="button-action-import"
                            @click.native="$refs.fileInput.click()"
                        >
                            <input
                                ref="fileInput"
                                type="file"
                                style="display: none;"
                                accept=".json"
                                @change="onFileImport"
                            >

                            <t class="hide-mobile">
                                Import
                            </t>

                            <span class="hide-desktop">
                                <FontAwesomeIcon
                                    :icon="['fal', 'plus']"
                                    class="plus"
                                />
                            </span>
                        </HtButton>

                        <HtButton
                            v-if="canExport"
                            cypress="button-action-import"
                            @click.native="onExport"
                        >
                            <t class="hide-mobile">
                                Export
                            </t>

                            <span class="hide-desktop">
                                <FontAwesomeIcon
                                    :icon="['fal', 'plus']"
                                    class="plus"
                                />
                            </span>
                        </HtButton>
                    </template>

                    <template #topFilter>
                        <HtSearchField
                            id="search"
                            v-model="search"
                            name="search"
                            class="search"
                        />
                    </template>
                </ResourceBarFilters>
            </div>

            <div class="ht-table-paginated">
                <div class="table-summary">
                    <div class="table-summary-results">
                        <t :count="totalItemCounts">
                            {count} result | {count} results
                        </t>
                    </div>
                </div>

                <HtTableExtended
                    :items="allItems"
                    :columns="allColumns"
                    cypress="action-resource-table"
                    :empty-message="emptyMessage"
                    :has-quick-actions="hasQuickActions"
                    :has-checkboxes="canHaveCheckboxes"
                    @on-row-clicked="onRowClicked"
                    @on-action-clicked="$emit('on-action-clicked', $event)"
                    @on-toggle-checkbox="onToggleCheckbox"
                    @on-toggle-all-checkbox="onCheckAll"
                    @sort-column="onSortColumn"
                >
                    <template #cell(resource.name)="scope">
                        <TableCellTextEllipsis
                            :text="scope.value"
                        />
                    </template>

                    <template #cell(participants)="scope">
                        <TableCellParticipants
                            v-if="scope.item.resource.programs[0] && scope.item.resource.programs[0].participants.length > 0"
                            :participants="scope.item.resource.programs[0].participants"
                        />
                        <span v-else>-</span>
                    </template>

                    <template #cell(program_name)="scope">
                        {{ scope.item.resource.programs[0]?.program?.name ?? '-' }}
                    </template>

                    <template #cell(filters)="scope">
                        <TableCellFilters
                            v-if="scope.item.resource.programs[0]"
                            :filters="scope.item.resource.programs[0].filters"
                        />
                        <span v-else>-</span>
                    </template>

                    <template #cell(status)="scope">
                        <HtTag :color="scope.item.status_color">
                            {{ scope.item.status_label }}
                        </HtTag>
                    </template>

                    <template #cell(is_locked)="scope">
                        <FontAwesomeIcon
                            v-if="scope.item.is_locked"
                            icon="lock"
                        />
                        <span v-else />
                    </template>

                    <template
                        v-for="(_, slot) in $slots"
                        #[slot]
                    >
                        <slot :name="slot" />
                    </template>

                    <template
                        v-for="(_, slot) in $scopedSlots"
                        #[slot]="slotData"
                    >
                        <slot
                            :name="slot"
                            v-bind="slotData || {}"
                        />
                    </template>
                </HtTableExtended>

                <HtPagination
                    class="mb-4"
                    :value="page"
                    :last-page="lastPage"
                    :goto-is-enabled="false"
                    @input="fetchData($event, perPage)"
                />
            </div>
        </div>

        <Modalable
            v-if="isModalable"
            ref="modalable"
            class="modalable-1 medium"
            :mode="2"
            @onClose="fetchData(page, perPage)"
        >
            <slot
                name="modalable"
                :selected-resource="selectedResource"
                :selected-resource-id="selectedResource ? selectedResource.id : null"
            />
        </Modalable>
    </div>
</template>

<script>
import { Skeleton } from 'vue-loading-skeleton';
import I18n from '@/modules/i18n/I18n';
import api from '@/store/api';
import Modalable from '@/components/globals/Modalable.vue';
import HtTag from '@/components/globals/HtTag.vue';
import HtButton from '@/components/globals/HtButton.vue';
import HtSearchField from '@/components/globals/HtSearchField.vue';
import HtPagination from '@/components/globals/HtPagination.vue';
import HtTableExtended from '@/components/globals/HtTable/HtTableExtended.vue';
import ResourceBarFilters from '@/components/resources/ResourceBarFilters.vue';
import TableCellFilters from '@/components/globals/table/TableCellFilters.vue';
import TableCellParticipants from '@/components/globals/table/TableCellParticipants.vue';
import TableCellTextEllipsis from '@/components/globals/table/TableCellTextEllipsis.vue';
import debounce from 'lodash.debounce';

export default {
    name: 'ResourceListing',

    components: {
        Skeleton,
        Modalable,
        HtTag,
        HtButton,
        HtSearchField,
        HtPagination,
        HtTableExtended,
        ResourceBarFilters,
        TableCellFilters,
        TableCellParticipants,
        TableCellTextEllipsis,
    },

    props: {
        apiName: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: I18n.translate('Create'),
        },
        columns: {
            type: Array,
            default: () => [],
        },
        permission: {
            type: String,
            required: true,
        },
        permissionTypeOnRowClicked: {
            type: String,
            default: 'update',
        },
        emptyMessage: {
            type: String,
            default: 'There are no resource to display yet...',
        },
        isModalable: {
            type: Boolean,
            default: true,
        },
        hasQuickActions: {
            type: Boolean,
            default: false,
        },
        hasCheckboxes: {
            type: Boolean,
            default: false,
        },
        formatter: {
            default: null,
        },
    },

    data() {
        return {
            items: [],
            search: null,
            sort: {
                column: 'id',
                direction: 'desc',
            },
            page: 1,
            lastPage: 1,
            perPage: 10,
            totalItemCounts: 0,
            selectedResource: null,
            internalItems: [],
            startColumns: [
                {
                    label: this.translate('Name'),
                    key: 'resource.name',
                    class: 'font-weight-bold',
                    sortable: true,
                    search: true,
                    style: {
                        'max-width': '256px',
                    },
                },
                {
                    label: this.translate('Participants'),
                    key: 'participants',
                    sortable: false,
                    search: false,
                },
                {
                    label: this.translate('Program'),
                    key: 'program_name',
                    sortable: false,
                    search: false,
                },
                {
                    label: this.translate('Filters'),
                    key: 'filters',
                    sortable: false,
                    search: false,
                },
            ],
            endColumns: [
                {
                    label: this.translate('Locked'),
                    key: 'is_locked',
                    sortable: false,
                    search: false,
                },
            ],
        };
    },

    computed: {
        allItems: {
            get() {
                return this.internalItems;
            },
            set(newItems) {
                this.internalItems = newItems;
            },
        },
        allColumns() {
            return [...this.startColumns, ...this.columns, ...this.endColumns];
        },
        isLoading() {
            return this.$store.state.resources.isLoading;
        },
        canImport() {
            return this.$Auth.isAutoConnectAdmin() && this.hasCheckboxes;
        },
        canExport() {
            return this.$Auth.isAutoConnectAdmin() && this.hasCheckboxes;
        },
        canHaveCheckboxes() {
            return this.canImport || this.canExport;
        },
    },

    created() {
        this.fetchData();
    },

    methods: {
        onSortColumn(sort) {
            const column = this.startColumns.find((col) => col.key === sort.column);
            if (!column?.sortable) {
                return;
            }

            this.sort.column = sort.column;
            this.sort.direction = sort.direction;
            this.fetchData();
        },
        fetchData(page = 1, perPage = 10) {
            this.$store.dispatch('resources/setLoading', true);
            api.configuration[this.apiName]
                .getAll({
                    search: this.search,
                    sort: {
                        column: this.sort.column,
                        direction: this.sort.direction,
                    },
                    filters: this.$store.state.resources.filters,
                    page,
                    per_page: perPage,
                })
                .then((res) => {
                    this.items = this.formatter !== null ? this.formatter(res.data.data) : res.data.data;
                    this.page = res.data.meta.current_page;
                    this.lastPage = res.data.meta.last_page;
                    this.totalItemCounts = res.data.meta.total;
                    this.$store.dispatch('resources/setLoading', false);
                });
        },
        onAdd() {
            if (this.isModalable) {
                this.selectedResource = null;
                this.$refs.modalable.open();
            } else {
                this.$emit('on-create');
            }
        },
        onRowClicked(row) {
            const hasPermission = this.permission && (row.item.permissions[`can_${this.permissionTypeOnRowClicked}`] ?? row.item.permissions[this.permissionTypeOnRowClicked]);

            if (!this.permission || hasPermission) {
                if (this.isModalable) {
                    this.selectedResource = row.item;
                    this.$refs.modalable.open();
                } else {
                    this.$emit('on-row-clicked', row);
                }
            }
        },
        onCheckAll(isAllChecked) {
            this.allItems = this.allItems.map((item) => ({ ...item, is_checked: isAllChecked }));
        },
        onToggleCheckbox(toggleItem) {
            this.allItems = this.allItems.map((item) => {
                if (item.id === toggleItem.id) {
                    return { ...item, is_checked: !toggleItem.is_checked };
                }
                return item;
            });
        },
        onExport() {
            const allChecked = this.allItems.filter((item) => item.is_checked);
            if (allChecked.length === 0) {
                this.$Notifier('App').showWarning(this.translate('Please check at least one item to process export.'));
                return;
            }

            this.$emit('on-export', allChecked);
        },
        onFileImport(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    try {
                        const jsonData = JSON.parse(e.target.result);
                        this.$emit('on-import', jsonData);
                    } catch (error) {
                        this.$Notifier('App').showError(this.translate('An error occured when reading json file'));
                    }
                };
                reader.readAsText(file);
                this.$refs.fileInput.value = null;
            }
        },
    },

    watch: {
        items: {
            handler(newItems) {
                this.internalItems = newItems.map((newItem) => ({
                    ...newItem,
                    is_checked: false,
                }));
            },
            immediate: true,
        },
        search: debounce(function () {
            this.fetchData();
        }, 500),
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/var';
/* TO REMOVE ONCE TABLE IS PAGINATED AND USES HtTablePagiated */
.ht-table-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .ht-table-header {
        .title {
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 4px;
            align-self: flex-start;
        }
    }

    .ht-table-paginated {
        background-color: $neutral-white;
        font-size: 14px;
        color: $neutral-800;
        border: 1px solid $neutral-300;
        border-radius: 8px;

        .table-summary {
            font-weight: 600;
            color: $neutral-black;
            padding: 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

@media (max-width: $tablet) {
    .hide-mobile {
        display: none;
    }
}

@media (min-width: $tablet) {
    .hide-desktop {
        display: none;
    }
}
</style>
